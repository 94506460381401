import React from "react"
import { graphql } from "gatsby"
import '../css/nofound.css'

class NotFoundPage extends React.Component {


  render() {
    // const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title

    return (
      <div className="nofound-container">
        <div className="nofound-text">
          <p className="title">404</p>
          <p>Not Found</p>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>

      </div>
    )
  }
}

export default NotFoundPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `
